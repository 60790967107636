import faqAccardeon from "./components/accardeon/index.vue"
import {mapActions, mapGetters} from "vuex";

export default {
    name: "faq",
    components:{faqAccardeon},
    computed: {
        ...mapGetters({
            questions: `pages/questions`
        })
    },
    created() {
        this.fetchQuestions()
    },
    methods: {
        ...mapActions({
            fetchQuestions: 'pages/GET_QUESTIONS'
        })
    }
}