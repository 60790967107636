import {VueSlideToggle} from "vue-slide-toggle";

export default {
  props: {
    info: {
      type: Object,
      default: () => {
      }
    }
  },
  data() {
    return {
      isOpenFaq: false
    }
  },
  components: {
    VueSlideToggle
  },
  name: "faq-accardeon"
}

